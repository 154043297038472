/* Default styles for larger screens */

  /* Media query for mobile screens (up to 768px) */
  @media (max-width: 768px) {
    #dm1 {
      width: 100% !important; /* Full width for mobile */
      font-size: 16px !important; /* Adjust font size for mobile */
      padding: 12px 15px !important; /* Adjust padding for mobile */
    }
    .right-widget {
        right: 4px !important;
    }

  }