
#desc-label,#price-label{
  display: none;
}


@media (max-width: 768px) {
   
  .react-tabs__tab{
    width: 100px !important;
  }

}